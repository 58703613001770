var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseAgingChart" }, [
    _c("div", { staticClass: "row row--align-center row--wrap" }, [
      _c("div", { staticClass: "column column--width-auto" }, [
        _c("label", { staticClass: "fc-light fs-12" }, [_vm._v("0-30")]),
        _c(
          "label",
          { staticClass: "BaseAgingChart__aging-amount fs-24 mt-1" },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.agingValueText(_vm.cat0to30.count)) +
                "\n        "
            ),
            _c("span", { staticClass: "fs-16" }, [
              _vm._v(
                "\n          ." +
                  _vm._s(_vm.agingValueText(_vm.cat0to30.count, true)) +
                  "\n        "
              )
            ])
          ]
        ),
        _c(
          "label",
          {
            staticClass:
              "BaseAgingChart__percent-lbl bg-green fc-white fs-12 mt-6"
          },
          [_vm._v("\n        " + _vm._s(_vm.cat0to30.percent) + "%\n      ")]
        )
      ]),
      _c("div", { staticClass: "column column--width-auto" }, [
        _c("label", { staticClass: "fc-light fs-12" }, [_vm._v("31-45")]),
        _c(
          "label",
          { staticClass: "BaseAgingChart__aging-amount fs-24 mt-1" },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.agingValueText(_vm.cat31to45.count)) +
                "\n        "
            ),
            _c("span", { staticClass: "fs-16" }, [
              _vm._v(
                "\n          ." +
                  _vm._s(_vm.agingValueText(_vm.cat31to45.count, true)) +
                  "\n        "
              )
            ])
          ]
        ),
        _c(
          "label",
          {
            staticClass:
              "BaseAgingChart__percent-lbl bg-green fc-white fs-12 mt-6"
          },
          [_vm._v("\n        " + _vm._s(_vm.cat31to45.percent) + "%\n      ")]
        )
      ]),
      _c("div", { staticClass: "column column--width-auto" }, [
        _c("label", { staticClass: "fc-light fs-12" }, [_vm._v("46-60")]),
        _c(
          "label",
          { staticClass: "BaseAgingChart__aging-amount fs-24 mt-1" },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.agingValueText(_vm.cat46to60.count)) +
                "\n        "
            ),
            _c("span", { staticClass: "fs-16" }, [
              _vm._v(
                "\n          ." +
                  _vm._s(_vm.agingValueText(_vm.cat46to60.count, true)) +
                  "\n        "
              )
            ])
          ]
        ),
        _c(
          "label",
          {
            staticClass:
              "BaseAgingChart__percent-lbl bg-green fc-white fs-12 mt-6"
          },
          [_vm._v("\n        " + _vm._s(_vm.cat46to60.percent) + "%\n      ")]
        )
      ]),
      _c("div", { staticClass: "column column--width-auto" }, [
        _c("label", { staticClass: "fc-light fs-12" }, [_vm._v("61-90")]),
        _c(
          "label",
          { staticClass: "BaseAgingChart__aging-amount fs-24 mt-1" },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.agingValueText(_vm.cat61to90.count)) +
                "\n        "
            ),
            _c("span", { staticClass: "fs-16" }, [
              _vm._v(
                "\n          ." +
                  _vm._s(_vm.agingValueText(_vm.cat61to90.count, true)) +
                  "\n        "
              )
            ])
          ]
        ),
        _c(
          "label",
          {
            staticClass:
              "BaseAgingChart__percent-lbl bg-green fc-white fs-12 mt-6"
          },
          [_vm._v("\n        " + _vm._s(_vm.cat61to90.percent) + "%\n      ")]
        )
      ]),
      _c("div", { staticClass: "column column--width-auto" }, [
        _c("label", { staticClass: "fc-light fs-12" }, [_vm._v("91+")]),
        _c(
          "label",
          { staticClass: "BaseAgingChart__aging-amount fs-24 mt-1" },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.agingValueText(_vm.cat91Plus.count)) +
                "\n        "
            ),
            _c("span", { staticClass: "fs-16" }, [
              _vm._v(
                "\n          ." +
                  _vm._s(_vm.agingValueText(_vm.cat91Plus.count, true)) +
                  "\n        "
              )
            ])
          ]
        ),
        _c(
          "label",
          {
            staticClass:
              "BaseAgingChart__percent-lbl bg-green fc-white fs-12 mt-6"
          },
          [_vm._v("\n        " + _vm._s(_vm.cat91Plus.percent) + "%\n      ")]
        )
      ]),
      _c("div", { staticClass: "column column--width-auto" }, [
        _c("label", { staticClass: "fc-blue fs-16" }, [_vm._v("Total")]),
        _c(
          "label",
          { staticClass: "BaseAgingChart__aging-amount fs-24 mt-1" },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.agingValueText(_vm.catTotal.count)) +
                "\n        "
            ),
            _c("span", { staticClass: "fs-16" }, [
              _vm._v(
                "\n          ." +
                  _vm._s(_vm.agingValueText(_vm.catTotal.count, true)) +
                  "\n        "
              )
            ])
          ]
        ),
        _c(
          "label",
          {
            staticClass:
              "BaseAgingChart__percent-lbl bg-blue fc-white fs-12 mt-6"
          },
          [_vm._v("\n        100%\n      ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }