<template>
  <div class="BaseAgingChart">
    <div class="row row--align-center row--wrap">
      <!-- 0-30 -->
      <div class="column column--width-auto">
        <label class="fc-light fs-12">0-30</label>
        <label class="BaseAgingChart__aging-amount fs-24 mt-1">
          {{ agingValueText(cat0to30.count) }}
          <span class="fs-16">
            .{{ agingValueText(cat0to30.count, true) }}
          </span>
        </label>
        <label class="BaseAgingChart__percent-lbl bg-green fc-white fs-12 mt-6">
          {{ cat0to30.percent }}%
        </label>
      </div>
      <!-- 31-45 -->
      <div class="column column--width-auto">
        <label class="fc-light fs-12">31-45</label>
        <label class="BaseAgingChart__aging-amount fs-24 mt-1">
          {{ agingValueText(cat31to45.count) }}
          <span class="fs-16">
            .{{ agingValueText(cat31to45.count, true) }}
          </span>
        </label>
        <label class="BaseAgingChart__percent-lbl bg-green fc-white fs-12 mt-6">
          {{ cat31to45.percent }}%
        </label>
      </div>
      <!-- 46-60 -->
      <div class="column column--width-auto">
        <label class="fc-light fs-12">46-60</label>
        <label class="BaseAgingChart__aging-amount fs-24 mt-1">
          {{ agingValueText(cat46to60.count) }}
          <span class="fs-16">
            .{{ agingValueText(cat46to60.count, true) }}
          </span>
        </label>
        <label class="BaseAgingChart__percent-lbl bg-green fc-white fs-12 mt-6">
          {{ cat46to60.percent }}%
        </label>
      </div>
      <!-- 61-90 -->
      <div class="column column--width-auto">
        <label class="fc-light fs-12">61-90</label>
        <label class="BaseAgingChart__aging-amount fs-24 mt-1">
          {{ agingValueText(cat61to90.count) }}
          <span class="fs-16">
            .{{ agingValueText(cat61to90.count, true) }}
          </span>
        </label>
        <label class="BaseAgingChart__percent-lbl bg-green fc-white fs-12 mt-6">
          {{ cat61to90.percent }}%
        </label>
      </div>
      <!-- 91+ -->
      <div class="column column--width-auto">
        <label class="fc-light fs-12">91+</label>
        <label class="BaseAgingChart__aging-amount fs-24 mt-1">
          {{ agingValueText(cat91Plus.count) }}
          <span class="fs-16">
            .{{ agingValueText(cat91Plus.count, true) }}
          </span>
        </label>
        <label class="BaseAgingChart__percent-lbl bg-green fc-white fs-12 mt-6">
          {{ cat91Plus.percent }}%
        </label>
      </div>
      <!-- Total -->
      <div class="column column--width-auto">
        <label class="fc-blue fs-16">Total</label>
        <label class="BaseAgingChart__aging-amount fs-24 mt-1">
          {{ agingValueText(catTotal.count) }}
          <span class="fs-16">
            .{{ agingValueText(catTotal.count, true) }}
          </span>
        </label>
        <label class="BaseAgingChart__percent-lbl bg-blue fc-white fs-12 mt-6">
          100%
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAgingChart',

  props: {
    cat0to30: {
      type: Object,
      required: true,
    },
    cat31to45: {
      type: Object,
      required: true,
    },
    cat46to60: {
      type: Object,
      required: true,
    },
    cat61to90: {
      type: Object,
      required: true,
    },
    cat91Plus: {
      type: Object,
      required: true,
    },
    catTotal: {
      type: Object,
      required: true,
    },
  },

  methods: {
    agingValueText (age, decimal = false) {
      return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
        .format(age)
        .split('.')[decimal ? 1 : 0]
    },
  },
}
</script>

<style lang="sass">
.BaseAgingChart

  .column--width-auto
    margin-right: rem(42px)
    margin-top: rem(38px)

    &:last-child
      margin-right: 0

  &__aging-amount
    line-height: rem(44px)

  &__percent-lbl
    display: inline-block
    padding: rem(3px) rem(7px)
</style>